<template>
  <div class="wrapper">

    <!-- <Slider></Slider> -->

    <hr class="mt-0">

    <div class="buttons-sec ">

      <div class="dropdown pr-1">
        <button class="btn btn-secondary  dropdown-toggle ddb" type="button" data-toggle="collapse" data-target="#collapseSports" aria-expanded="false" aria-controls="collapseExample">
          Sports
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        </div>
      </div>

      <div class="dropdown pr-1">
        <button class="btn btn-secondary  dropdown-toggle ddb" type="button" data-toggle="collapse" data-target="#collapseCountries" aria-expanded="false" aria-controls="collapseExample">
          Countries
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

        </div>
      </div>

      <div class="dropdown pr-1">
        <button class="btn btn-secondary  dropdown-toggle ddb" type="button" data-toggle="collapse" data-target="#collapseLeagues" aria-expanded="false" aria-controls="collapseExample">
          Leagues
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

        </div>
      </div>

      <div class="dropdown">
        <button id="market-btn" class="btn btn-secondary  dropdown-toggle ddb" type="button" data-toggle="collapse" data-target="#collapseMarkets" aria-expanded="false" aria-controls="collapseExample">
          Markets
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"></div>
      </div>

    </div>

    <div id="accordion">
      <Sports></Sports>
      <Countries></Countries>
      <Markets></Markets>
      <Leagues></Leagues>
    </div>

    <div class="highlight-box px-4 pt-3" style="display: none">
      <section class="bets-menu d-flex justify-content-between mt-1 mb-1 ">

        <div class="w-20 p-1 px-3 active" @click="goTo('highlights')">
          Highlights
        </div>

        <div class="w-20 p-1 px-3 text-blue">
          <a class="text-blue" style="text-decoration: none;" @click="goTo('today')">Today</a>
        </div>

        <div class="w-20 p-1 px-3 text-blue">
          <a class="text-blue" style="text-decoration: none;" @click="goTo('upcoming')">Upcoming</a>
        </div>

        <div class="w-20 p-1 px-3 text-blue d-none">
          Chap! Pick
        </div>

      </section>
    </div>

    <hr>

    <Alllive v-bind:sport="sport_id" upcoming="0" leo="true" v-show="current_sub_page !== 'countries' "></Alllive>

    <BottomNavigation></BottomNavigation>

  </div>
</template>

<script>
import Alllive from './Alllive'
import BottomNavigation from './BottomNavigation'
const Markets = () => import('./accordion/markets.vue')
const Sports = () => import('./accordion/sports.vue')
const Countries = () => import('./accordion/countries.vue')
const Leagues = () => import('./accordion/leagues.vue')

export default {
  name: 'Live',
  components: {
    Alllive,
    Markets,
    Sports,
    Countries,
    Leagues,
    BottomNavigation
  },
  data: function (){

    return {
      sub_page: '',
    }

  },
  mounted() {

    this.$store.dispatch("setCurrentPage","live");
    //this.$store.dispatch("setSportID",1);
    this.$store.dispatch("setHour",-1);
    //this.$store.dispatch("setMarketID",1);
    this.$store.dispatch("setCompetitionID",0);

    // reset game filter
    //this.$store.dispatch("resetAllGames");
    this.reloadProfile();

  },
  computed: {
    betslip_count: function (){

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {

      return this.$store.state.sport_id;
    }
  },
  methods: {

    setSubPage: function (page){

      this.$store.dispatch("setCurrentSubPage",page);
      this.sub_page = page;

    },
    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    setSport: function(sportID) {

      this.$store.dispatch("setSportID",sportID);

    }
  }
}
</script>