<template>

  <section class="boosted-odds-section flexer">
    <div class="b-o-t text-blue mb-2">
      BOOSTED GAMES
    </div>

    <div class="boosted-odds-wrapper d-flex">

      <div class="boosted-odds-card mr-1" v-for="f in highlightsRawData" v-bind:key="getKey(f.match_id)">

        <a style="text-decoration: none;">
          <div class="grey-text mb-2 league-text">
            <span v-text="f.tournament"></span> <span class="text-light mx-3">{{ formatMatchDate(f.date) }}</span> #<span v-text="f.game_id"></span>
          </div>
        </a>

        <div class="boosted-odds-game-row  m-0 px-2">

          <a style="text-decoration: none;">

            <div class="row m-0 p-0">

              <div class="col-6 p-0 m-0">
                <div class="text-light text-left text-home-away-team" v-text="f.home_team"></div>
              </div>

              <div class="col-6 p-0 m-0">
                <div class="text-light text-right text-home-away-team" v-text="f.away_team"></div>
              </div>

            </div>

          </a>

          <div class="row">
            <div
                v-bind:id="getID(o.odd_id)"
                v-for="o in f.outcomes"
                v-bind:key="getKey(o.odd_id)"
                class="col-4"
                v-bind:class="getClasses(o)">
              <Odd
                  v-bind:sport_id="f.sport_id"
                  v-bind:match_id="f.match_id"
                  v-bind:market_name="o.market_name"
                  v-bind:home_team="f.home_team"
                  v-bind:away_team="f.away_team"
                  v-bind:market_id="o.market_id"
                  v-bind:outcome_id="o.outcome_id"
                  v-bind:specifier="o.specifier"
                  v-bind:active="o.active"
                  v-bind:status="o.status"
                  v-bind:odds="o.odd"
                  v-bind:producer_id="o.producer_id"
                  v-bind:producer_status="o.producer_status"
                  v-bind:previous_odds="o.previous_odds"
                  v-bind:alias="o.alias"
                  v-bind:probability="o.probability"
                  boosted=true
                  v-bind:pick="o"/>
            </div>
          </div>
        </div>

      </div>

    </div>

  </section>

</template>

<script>

import Odd from './Odd'
import axios from "@/services/api";

export default {
  name: "Boosted",
  methods: {
    getID: function(id){

      return 'boosted-'+id;

    },
    getPopularGames: function () {

      var vm = this;

      var path = process.env.VUE_APP_URL_POPULAR;

      path = path.replace("{sport_id}", vm.sport_id);

      axios.post(path, JSON.stringify({}))
          .then(res => {

            vm.highlightsRawData = res.data.message;

          })
          .catch(err => {

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    getClasses: function (o) {


      //var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? 'btn-odd' : 'btn-odd';

      return "" +
          " match-" + o.match_id +
          " producer-" + o.producer_id +
          " market-" + o.market_id +
          " outcome-" + o.outcome_id +
          " specifier-" + o.specifier;
    },

    getKey: function(match_id) {

      var prefix = "popular-games-"+match_id;
      return Math.random().toString(10).replace('0.', 'odd-id-'+prefix + '-');

    },
    setSport: function (sportID) {

        this.$store.dispatch("setSportID",sportID);

        if(this.$store.state.current_page === 'home')
          return

        this.$router.push({name: 'home'});
    },
    getMenuTextClass: function (sportID){

      return this.current_page === "esport" || this.current_page === "freebet" ? "" :  parseInt(this.$store.state.sport_id) === parseInt(sportID) ? "menu-active" : "";

    },
    isActiveMenu: function (name){

      return this.current_page === name ? "menu-active" : "";

    },
    resetGames: function(){

      this.$store.dispatch("resetAllGames");

      //if(this.current_page !== "home") {

        this.goHome();

      //}

    }
  },
  computed: {
    sportIcon: function () {

      return this.getSportIcon(this.sport_id);

    },
    iconSize: function (){

      return 24;

    },
    current_page: function () {

      return this.$store.state.current_page
    },
    show_freebet: function (){

      var p = this.getProfile();
      if(!p) {

        return true;

      }

      return parseInt(p.b4) > 0;

    },
    sports: function() {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s,function(k,v){

        if(v.sport_id > 1 ) {

          t.push(v);
        }

      })

      return t;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
    sport_name: function () {
      return this.$store.state.sport_name;
    },
  },
  components: {
    Odd
  },
  watch: {
    sport_id: function (newValue, oldValue) {

      if(parseInt(newValue) === parseInt(oldValue)) {

        return;

      }

      this.getPopularGames();

    },
  },
  data: function() {
    return {
      highlightsRawData:[]
    }
  },
  mounted: function () {

    this.getPopularGames();

  }
  }
</script>