<template>
  <div class="wrapper">

    <Menu></Menu>

    <Slider></Slider>

    <Boosted></Boosted>

    <div class="body mt-1">
      <Highlights></Highlights>

      <div class="sport_dropdowns">

        <!-- Sports dropdown -->
        <div class="btn-group">

          <span
              class="dropdown-filter dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              v-text="sport_name">
          </span>

          <div class="dropdown-menu">

            <span
                v-for="s in sports"
                v-bind:key="getKey(s.sport_id)"
                class="dropdown-item sport_dropdown_item black-txt"
                @click="setSport(s)"
                v-text="s.sport_name">
            </span>

          </div>

        </div>

        <!-- Sports dropdown -->
        <div class="btn-group">
          <span
              class="dropdown-filter"
              v-bind:class="geChildPageActiveClass('')"
              @click="setUpcomingAll">
            All
          </span>
        </div>

        <!-- Top Leagues dropdown -->
        <div class="btn-group" v-for="(d,index) in dates" v-bind:key="index">
          <span
              class="dropdown-filter"
              v-bind:class="geChildPageActiveClass(d.date)"
              @click="setUpcomingDate(d.date)">
            {{ getDayName(index,d.label) }}
          </span>
        </div>

      </div>

      <Games upcoming="1" v-bind:date="date"></Games>

      <Trending class=""></Trending>
      <BottomFooter></BottomFooter>
      <bottom-navigation active-bets="0" page="today"></bottom-navigation>
    </div>

  </div>
</template>

<script>
import Highlights from './Highlights'
import Slider from './Slider'
import Games from './Games'
import BottomFooter from './BottomFooter'
import Menu from './Menu'
import Boosted from './Boosted'
import BottomNavigation from './BottomNavigation'
import Trending from './Trending'

export default {
  name: 'Upcoming',
  components: {
    Menu,
    Slider,
    Highlights,
    Games,
    BottomFooter,
    BottomNavigation,
    Trending,
    Boosted
  },
  data: function (){
    return {
      sub_page: '',
      dates: [],
      sport_name: 'Sports',
    }
  },
  mounted: function() {

    this.$store.dispatch("setCurrentPage","upcoming");
    var d1 = this.daysFromNow(1);
    var d2 = this.daysFromNow(2);
    var d3 = this.daysFromNow(3);
    //var d4 = this.daysFromNow(4);

    this.dates.push({
      date: d1,
      label: this.getDayOfWeek(d1)
    });

    this.dates.push({
      date: d2,
      label: this.getDayOfWeek(d2)
    });

    this.dates.push({
      date: d3,
      label: this.getDayOfWeek(d3)
    });

    /*
    this.dates.push({
      date: d4,
      label: this.getDayOfWeek(d4)
    });
    */

    this.autoRefreshUI(this.$vnode.tag);
    this.reloadProfile();

  },
  computed: {
    sports: function () {

      return this.$store.state.sports
    },

    betslip_count: function (){

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    date: function (){

      return this.$store.state.upcoming_date

    }
  },
  methods: {
    getKey: function (index) {

      return Math.random().toString(20).replace('0.', 'random-id-' + index + '-');

    },
    geChildPageActiveClass: function (childpage) {

      return childpage === this.$store.state.upcoming_date ? 'selected-date' : "";

    },
    setUpcomingDate: function (date){

      this.$store.dispatch("setUpcomingDate",date)

    },
    setUpcomingAll: function (){

      this.$store.dispatch("setUpcomingDate",'')

    },
    getDayName: function(i,label){

      console.log('index ==> '+i+' label ==> '+label);

      if(i === 0 ) {

        return 'Tomorrow';
      }

      return label;

    },

    setSubPage: function (page){

      this.$store.dispatch("setCurrentSubPage",page);
      this.sub_page = page;

    },
    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    setSport: function (sport) {

      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.$store.dispatch("setSportID", sport.sport_id);
      this.$store.dispatch("setSportName", sport.sport_name);
    },

  }
}
</script>