<template>

  <div class="wrapper"  style="">

    <section class="px-2 mb-3">

      <div class="form-wrapper mb-0">
        <div class="input-group mb-0">

          <input @keyup.enter="search" type="text" class="form-control" id="search-input" name="keyword" placeholder="Search for games, leagues, events" aria-label="Search for games, leagues, events"  v-model="to_search" style="background-color:white; color: black;">
           <div class="input-group-append" @click="search">
            <span class="input-group-text text-light" style="background-color: var(--lighestgray1);"><i class="bi bi-search mr-1"></i> Search </span>
          </div>
        </div>
      </div>
      <div class="row text-light py-1 px-1 mb-2 d-none text-center search-bar">
        <div class="col-12">
 Search Results
        </div>
       
      </div>

      <Games v-bind:sport="sport_id" v-bind:search="keywords" v-bind:searchable="searchable"></Games>

      <div class="text-center slip-ttxt py-3 d-none">
        <div class="text-blue mb-3">Get the Jetpesa games booklet for free</div>
        <div>
          <a href="" class="login-button py-2 px-4 ">Download latest games booklet</a>
        </div>
      </div>

      <div v-show="no_results" class="text-light text-center d-none">
        No results found for<br>
        "{{ to_search }}"
      </div>

    </section>

  </div>

</template>

<script>

//import Menu from './Menu'

import Games from "./Games";

export default {
  name: 'Search',
  components: {
    Games,
  },
  data: function (){

    return {
      sport_id: 1,
      searchable: true,
      to_search: '',
      keywords: '',
      sub_page: '',
      results: '',
      busy: false,
      no_results: false,
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","search");
    this.$store.dispatch("setCommitPlaceHolderMatches",[]);
    window.scrollBy({
              top: 0,
              behavior: 'smooth'
            });

  },
  computed: {
    betslip_count: function (){

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    current_page: function () {

      return this.$store.state.current_page;
    }
  },
  methods: {
    perfomKeyUpSearch: function(){

    },
    getKey: function(fixture,index){

      if(Array.isArray(fixture)) {

        var currentFixture = fixture[0];

      } else {

        currentFixture = fixture

      }

      var prefix = currentFixture === undefined || currentFixture.match_id == undefined ? index : currentFixture.match_id;
      return Math.random().toString(10).replace('0.', 'fixture-id-'+prefix + '-');

    },

    setSubPage: function (page){

      this.$store.dispatch("setCurrentSubPage",page);
      this.sub_page = page;

    },
    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    setSport: function(sportID) {

      this.$store.dispatch("setSportID",sportID);

    },
    search: function () {

      var vm = this;
      vm.keywords = vm.to_search;
    },

  }
}
</script>